<template>
  <div id="ip-tools">
    <h1>{{ $t('system.ip_tools') }}</h1>
    <p>{{ $t('views.tools.ip_tools.note') }}</p>
    <b-card :title="$t('views.tools.ip_tools.expand_compress_address')">
      <b-form-group :label="$t('system.ipv6_address')">
        <b-form-input v-model="ip_expand_compress" :placeholder="$t('system.ipv6_address')"
                      :state="checkIPExpandCompress" required></b-form-input>
        <b-form-invalid-feedback id="input-live-feedback-expand">
          {{ $t('system.invalid_ipv6') }}
        </b-form-invalid-feedback>
      </b-form-group>
      <b-form-group :label="$t('views.tools.ip_tools.expanded_address')">
        <CopyField :text="expandIP" code/>
      </b-form-group>
      <b-form-group :label="$t('views.tools.ip_tools.compressed_address')">
        <CopyField :text="compressIP" code/>
      </b-form-group>
    </b-card>
    <br>
    <b-card :title="$t('views.tools.ip_tools.subnet_calculator')">
      <b-form-group :label="$t('views.tools.ip_tools.ipv6_subnet')">
        <b-form-input v-model="subnet" :placeholder="$t('views.tools.ip_tools.ipv6_subnet')"
                      :state="checkIPSubnet" required></b-form-input>
        <b-form-invalid-feedback id="input-live-feedback-compress">
          {{ $t('views.tools.ip_tools.invalid_ipv6_subnet') }}
        </b-form-invalid-feedback>
      </b-form-group>
      <b-form-group :label="$t('system.start')">
        <CopyField :text="calculateRange.start" code/>
      </b-form-group>
      <b-form-group :label="$t('system.end')">
        <CopyField :text="calculateRange.end" code/>
      </b-form-group>
    </b-card>
    <br>
    <b-card :title="$t('views.tools.ip_tools.mac_to_local_link')">
      <b-form-group :label="$t('system.mac_address')">
        <b-form-input v-model="mac_to_local_link" :placeholder="$t('system.mac_address')"
                      :state="checkMacAddress" required></b-form-input>
        <b-form-invalid-feedback id="input-live-feedback-compress">
          {{ $t('views.tools.ip_tools.invalid_mac_address') }}
        </b-form-invalid-feedback>
      </b-form-group>
      <b-form-group :label="$t('views.tools.ip_tools.local_link')">
        <CopyField :text="localLink" code/>
      </b-form-group>
    </b-card>
    <br>
    <b-card :title="$t('views.tools.ip_tools.ipv6_to_mac')">
      <b-form-group :label="$t('system.ipv6_address')">
        <b-form-input v-model="local_link_to_mac" :placeholder="$t('system.ipv6_address')"
                      :state="checkEUIIPv6Address" required></b-form-input>
        <b-form-invalid-feedback id="input-live-feedback-compress">
          <span v-if="checkEUI_IPv6">
            {{ $t('views.tools.ip_tools.ipv6_no_eui') }}
          </span>
          <span v-else>
            {{ $t('system.invalid_ipv6') }}
          </span>
        </b-form-invalid-feedback>
      </b-form-group>
      <b-form-group :label="$t('system.mac_address')">
        <CopyField :text="macAddress" code/>
      </b-form-group>
    </b-card>
  </div>
</template>

<script>
import CopyField from '@/components/CopyField.vue'
import ipaddress from '@/util/ipaddress'

export default {
  name: 'IPv6',
  components: { CopyField },
  computed: {
    expandIP() {
      return ipaddress.normalize_ipv6(this.ip_expand_compress)
    },
    compressIP() {
      return ipaddress.abbreviate_ipv6(this.ip_expand_compress)
    },
    calculateRange() {
      if (ipaddress.check_ipv6_subnet(this.subnet)) {
        const split = this.subnet.split('/')
        return ipaddress.ipv6_range(split[0], split[1], 128)
      } else {
        return {
          start: '',
          end: '',
          size: 0
        }
      }
    },
    localLink() {
      return ipaddress.mac_to_local_link_ipv6(this.mac_to_local_link)
    },
    macAddress() {
      return ipaddress.eui_ipv6_to_mac(this.local_link_to_mac)
    },
    checkIPExpandCompress() {
      if (!this.ip_expand_compress) {
        return null
      }
      return ipaddress.is_ip_v6(this.ip_expand_compress)
    },
    checkIPSubnet() {
      if (!this.subnet) {
        return null
      }
      return ipaddress.check_ipv6_subnet(this.subnet)
    },
    checkMacAddress() {
      if (!this.mac_to_local_link) {
        return null
      }
      return ipaddress.is_mac_address(this.mac_to_local_link)
    },
    checkEUIIPv6Address() {
      if (!this.local_link_to_mac) {
        return null
      }
      return (ipaddress.is_ip_v6(this.local_link_to_mac) && ipaddress.is_eui_ipv6(this.local_link_to_mac))
    },
    checkEUI_IPv6() {
      if (!this.local_link_to_mac) {
        return null
      }
      return ipaddress.is_ip_v6(this.local_link_to_mac)
    }
  },
  data() {
    return {
      ip_expand_compress: '',
      subnet: '',
      mac_to_local_link: '',
      local_link_to_mac: ''
    }
  }
}
</script>

<style scoped>

</style>
